import { forwardRef } from 'react';
import { IconWrapper } from '../IconWrapper';

export const SVGFlagBN = forwardRef((props, ref) => {
  return (
    <IconWrapper ref={ref} data-component={'svg-flag-bn'} {...props} >
      <circle cx={'12'} cy={'12'} r={'12'} fill={'#006a4e'} />
      <circle cx={'12'} cy={'12'} r={'6'} fill={'#d80027'} />
    </IconWrapper >
  );
});
